import { Component, OnInit } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ComunicazioniService } from '../comunicazioni.service';
import { ActivatedRoute } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-comunicazioni-lev1',
  templateUrl: './comunicazioni-lev1.component.html',
  styleUrls: ['./comunicazioni-lev1.component.scss']
})
export class ComunicazioniLev1Component implements OnInit {
  arrayData: any[any];
  titoloPagina: string;

  constructor(
    private formComponentsService: FormInputService,
    private service: ComunicazioniService,
    private route: ActivatedRoute,
    private mainEngineService: MainEngineService,

  ) {
    this.titoloPagina = this.service.titoloPagina;
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.service.getElencoMacroCategorie()
    .subscribe(data => {
      this.arrayData = data.items;
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.formComponentsService.activateLoader(false);
      this.mainEngineService.errorHandler(error);
    });
  }

}
