import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class ComunicazioniService {
  apiUrl: string;
  linkBase: string = "comunicazioni";
  titoloPagina: string = "Comunicazioni";

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  getElencoMacroCategorie(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/macrocategorie')
  }

  getElencoCategorie(idMacrocategoria: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/categorie?idMacrocategoria=' + idMacrocategoria)
  }

  getElencoComunicazioni(idCategoria: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elenco?idCategoria=' + idCategoria)
  }

  getElemento(idItem: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + idItem)
  }

}
