import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarioLezioniService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/calendarioLezioni/';
  }

  getParametriCalendario(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/op/parametriCalendario" )
  }

  getItem(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + id)
  }

  getStatoPresenza(idStudente: number, idCalendario: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/op/statoPresenza?idStudente=" + idStudente + "&idCalendario=" + idCalendario)
  }

  sendPresenza(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/presenza' , body);
  }

  terminaRegistrazione(idLezione: number) {
    return this.http.post<any>(this.apiUrl + 'op/terminaRegistrazione/' , {idLezione});
  }

}
