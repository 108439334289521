import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassiElencoComponent } from './classi-elenco/classi-elenco.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  declarations: [
    ClassiElencoComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    MatTableModule,
  ]
})
export class ClassiModule { }
