import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { CalendarioLezioniService } from '../calendario-lezioni.service';
import { LoaderService } from 'src/app/service/loader.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ReservedAccessService } from 'src/app/components/reservedAccess/reserved-access.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { UserLogged } from 'src/app/components/reservedAccess/user-logged';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendario-lezioni-elenco',
  templateUrl: './calendario-lezioni-elenco.component.html',
  styleUrls: ['./calendario-lezioni-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarioLezioniElencoComponent implements OnInit {
  titoloScheda: string;
  calendarOptions: CalendarOptions;
  user: UserLogged;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private service: CalendarioLezioniService,
    private loader: LoaderService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private reservedAccessService: ReservedAccessService,
    private formComponentsService: FormInputService,
    private router: Router,
  ) {
    this.reservedAccessService.getUserBasicDataSubject().subscribe(data => {
      this.user = data;
    });

  }

  ngOnInit(): void {
    $(window).on('resize', function() {
      const x = $('.card').offset();
      const z = $('.footer').offset();
      let calculatedHeight = z.top - x.top - 120;
      if (calculatedHeight < 300) {
        calculatedHeight = 300;
      }
      $('.card .calendarBody').height(calculatedHeight) ;
//      console.log("TopX: " + x);
//      console.log("TopZ: " + z.top);
//      console.log("H: " + (z.top-x-10));
   }).trigger('resize'); // on page load

    this.titoloScheda = "Calendario lezioni";

    const that = this;
    this.calendarOptions = {
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'prev,next'
      },
      views: {
        listWeek: {
          buttonText: 'Lista',
          duration: { days: 1 }
        }
      },

      timeZone: 'Europe/Rome',
      locale: 'it',
      weekNumbers: true,
      weekNumberCalculation: 'ISO',
      nowIndicator: true,
      slotLabelFormat: {hour: '2-digit', minute: '2-digit'},
      allDaySlot: false,

      initialView: "listWeek",
      themeSystem: "bootstrap",
      weekends: true,
      handleWindowResize: true,
      height: '100%',

      buttonText: {
        today:    'Oggi'
      },

      eventClick: function(info) {
//        if (!info.event._def.extendedProps.registrazioneTerminata) {
          that.router.navigate(['/calendarioLezioni/' + info.event._def.publicId]);
//        }
      },

      eventDidMount: function(info) {
        let stringa: string = info.el.innerHTML;
        stringa = stringa.split('{{').join('<');
        stringa = stringa.split('}}').join('>');
        info.el.innerHTML = stringa;
        $('td.fc-list-event-time').remove();
      }


    };
    this.getParametri();
  }

  getParametri(): void {
    this.service.getParametriCalendario()
    .subscribe(data => {
      if (data.empty) {
        $(".calendarBody").hide();
        $(".noCalendar").show();
        this.loader.spin.next(false);
      }
      else {
        $(".noCalendar").hide();
        $(".calendarBody").show();
        this.calendarOptions.slotMinTime = data.orarioMin;
        this.calendarOptions.slotMaxTime = data.orarioMax;
        this.calendarOptions.slotDuration = '00:15:00';
        this.calendarOptions.slotLabelInterval = '00:15:00';

        this.calendarOptions.events = this.mainEngineService.baseUrl + 'calendarioLezioni/?uId=' + this.user.id;
      }
    }, error => {
      this.notificationsService.show('error', '', error.error.message);
      this.loader.spin.next(false);
    });
  }

}
