import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';

@Injectable({
  providedIn: 'root'
})
export class ClassiService {
  apiUrl: string;
  linkBase: string = "classi";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayRicerca: any[any] = [];
  arrayBackupRicerca: any[any] = [];
  keepBackupRicerca: boolean = false;
  fromRicerca: boolean = false;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/classi/';
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setParametriRicerca(formValue: any[]) {
    this.arrayRicerca = formValue;
    this.fromRicerca = true;
    this.keepBackupRicerca = false;
  }

  getParametriRicerca() {
    return this.arrayBackupRicerca;
  }

  getElenco(): Observable<any[]> {
    let dummy = "";
    if (this.keepBackupRicerca) {
      dummy = this.arrayBackupRicerca;
    }
    else {
      if (this.fromRicerca) {
        dummy = this.arrayRicerca;
        this.fromRicerca = false;
        this.keepBackupRicerca = true;
        this.arrayBackupRicerca = this.arrayRicerca;
        this.arrayRicerca = [];
      }
      else {
        dummy = null;
        this.arrayBackupRicerca = [];
      }
    }
    return this.http.patch<any[]>(this.apiUrl, dummy)
  }


  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }

}
