import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarioLezioniElencoComponent } from './calendario-lezioni-elenco/calendario-lezioni-elenco.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import listPlugin from '@fullcalendar/list';
import { CalendarioLezioniSchedaComponent } from './calendario-lezioni-scheda/calendario-lezioni-scheda.component';
import { AppRoutingModule } from 'src/app/app-routing.module';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin
]);

@NgModule({
  declarations: [
    CalendarioLezioniElencoComponent,
    CalendarioLezioniSchedaComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    ComponentsModule,
    NgbModule,
    MatTableModule,
    FullCalendarModule

  ]
})
export class CalendarioLezioniModule { }
