import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { LocalLoginLayoutComponent } from './custom/layouts/local-login-layout/local-login-layout.component';
import { LocalMainLayoutComponent } from './custom/layouts/local-main-layout/local-main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: LocalMainLayoutComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./components/layouts/main-layout/main-layout.module').then(m => m.MainLayoutModule)
  },

{
    path: '',
    component: LocalLoginLayoutComponent,
    loadChildren: () => import('./components/layouts/login-layout/login-layout.module').then(m => m.LoginLayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
