import { Component, OnInit } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ComunicazioniService } from '../comunicazioni.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-comunicazioni-lev2',
  templateUrl: './comunicazioni-lev2.component.html',
  styleUrls: ['./comunicazioni-lev2.component.scss']
})
export class ComunicazioniLev2Component implements OnInit {
  arrayData: any[any];
  titoloPagina: string;
  idMacrocategoria: number = 0;
  titoloMacrocategoria: string;

  constructor(
    private formComponentsService: FormInputService,
    private service: ComunicazioniService,
    private route: ActivatedRoute,
    private router: Router,
    private mainEngineService: MainEngineService,

  ) {
    this.titoloPagina = this.service.titoloPagina;
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);
    this.route.params.subscribe(
      (params) => {
        this.idMacrocategoria = params.idCategoria;

        this.service.getElencoCategorie(this.idMacrocategoria)
        .subscribe(data => {
          this.arrayData = data.items;
          this.titoloMacrocategoria = data.titoloMacrocategoria;
          this.formComponentsService.activateLoader(false);
        },
        (error) => {
          this.formComponentsService.activateLoader(false);
          this.mainEngineService.errorHandler(error);
        });

      }
    );
  }

  onBack() {
    this.router.navigate(['/comunicazioni']);
  }

}
