import { Component, OnInit } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ComunicazioniService } from '../comunicazioni.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-comunicazioni-dettaglio',
  templateUrl: './comunicazioni-dettaglio.component.html',
  styleUrls: ['./comunicazioni-dettaglio.component.scss']
})
export class ComunicazioniDettaglioComponent implements OnInit {
  myItem: any;
  titoloPagina: string;
  idItem: number = 0;

  constructor(
    private formComponentsService: FormInputService,
    private service: ComunicazioniService,
    private route: ActivatedRoute,
    private router: Router,
    private mainEngineService: MainEngineService,

  ) {
    this.titoloPagina = this.service.titoloPagina;
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);
    this.route.params.subscribe(
      (params) => {
        this.idItem = params.idItem;

        this.service.getElemento(this.idItem)
        .subscribe(data => {
          this.myItem = data;
          this.formComponentsService.activateLoader(false);
        },
        (error) => {
          this.formComponentsService.activateLoader(false);
          this.mainEngineService.errorHandler(error);
        });

      }
    );
  }

  onBack() {
    this.router.navigate(['/comunicazioni/lev2/' + this.myItem.idCategoria]);
  }


}
