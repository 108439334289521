import { Routes } from '@angular/router';
import { InfoVersioneComponent } from 'src/app/components/info-versione/info-versione.component';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { ProfiloComponent } from 'src/app/components/profilo/profilo.component';
import { CalendarioLezioniElencoComponent } from 'src/app/pages/calendario-lezioni/calendario-lezioni-elenco/calendario-lezioni-elenco.component';
import { CalendarioLezioniSchedaComponent } from 'src/app/pages/calendario-lezioni/calendario-lezioni-scheda/calendario-lezioni-scheda.component';
import { ClassiElencoComponent } from 'src/app/pages/classi/classi-elenco/classi-elenco.component';
import { ComunicazioniDettaglioComponent } from 'src/app/pages/comunicazioni/comunicazioni-dettaglio/comunicazioni-dettaglio.component';
import { ComunicazioniElencoComponent } from 'src/app/pages/comunicazioni/comunicazioni-elenco/comunicazioni-elenco.component';
import { ComunicazioniLev1Component } from 'src/app/pages/comunicazioni/comunicazioni-lev1/comunicazioni-lev1.component';
import { ComunicazioniLev2Component } from 'src/app/pages/comunicazioni/comunicazioni-lev2/comunicazioni-lev2.component';
import { HomepageComponent } from 'src/app/pages/homepage/homepage.component';
import { OxfordGroveMusicOnlineComponent } from 'src/app/pages/oxford-grove-music-online/oxford-grove-music-online.component';


export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: 'profilo',
    component: ProfiloComponent
  },
  {
    path: 'calendarioLezioni',
    component: CalendarioLezioniElencoComponent
  },
  {
    path: 'calendarioLezioni/:idLezione',
    component: CalendarioLezioniSchedaComponent
  },
  {
    path: 'classi',
    component: ClassiElencoComponent
  },
  {
    path: 'comunicazioni/lev1/:idCategoria',
    component: ComunicazioniLev2Component
  },
  {
    path: 'comunicazioni/lev2/:idCategoria',
    component: ComunicazioniElencoComponent
  },
  {
    path: 'comunicazioni/:idItem',
    component: ComunicazioniDettaglioComponent
  },
  {
    path: 'comunicazioni',
    component: ComunicazioniLev1Component
  },
  {
    path: 'oxfordGroveMusicOnline',
    component: OxfordGroveMusicOnlineComponent
  },
  {
    path: 'infoVersione',
    component: InfoVersioneComponent
  },
  {
    path: '404',
    component: Page404Component
  },
];

