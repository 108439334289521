import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { CalendarioLezioniService } from '../calendario-lezioni.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators } from '@angular/forms';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { first } from 'rxjs/operators';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-calendario-lezioni-scheda',
  templateUrl: './calendario-lezioni-scheda.component.html',
  styleUrls: ['./calendario-lezioni-scheda.component.scss']
})
export class CalendarioLezioniSchedaComponent implements OnInit {
  backUrl = '';
  myItem: any;
  myPresenza: any;
  titoloScheda: string = "Caricamento...";
  arrayCampi: any[any];
  presente: number = 0;
  myForm: FormGroup;
  loading = false;
  idLezione: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private service: CalendarioLezioniService,
    private buttonService: ButtonService,
    private notificationsService: NotificationsService,
    private modalService: NgbModal
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'id_calendario' : {'id' : 'id_calendario', 'defValue' : 0, 'type': 'NUM'},
      'id_studente' : {'id' : 'id_studente', 'defValue' : 0, 'type': 'NUM'},
      'presente' : {'id' : 'presente', 'nome': 'Rilevazione', 'items': {0: 'Assente', 1:'Presente'}, 'type': 'RAD', 'required': true,'inline' : true},
      'inizio' : {'id' : 'inizio', 'nome': 'Ora di ingresso', 'type': 'ORA', 'required': true},
      'fine' : {'id' : 'fine', 'nome': 'Ora di uscita', 'type': 'ORA', 'required': true},
      'note' : {'id' : 'note', 'nome': 'Note', 'type': 'TXA'},
    };
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    this.formComponentsService.activateLoader(true);
  }

  ngOnInit(): void {

    this.route.params.subscribe(
      (params) => {
        if (params.idLezione != undefined) {
          this.formComponentsService.activateLoader(true);
          this.backUrl += '/lastItem/' + params.idLezione;
          this.idLezione = params.idLezione;
          this.getElencoStudenti();
        }
      });
  }


  getElencoStudenti() {
    this.service.getItem(this.idLezione)
    .subscribe(data => {
      this.myItem = data;

      this.titoloScheda = "Gestione lezione";
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.formComponentsService.activateLoader(false);
      this.mainEngineService.errorHandler(error);
    });
  }

  openModalPresenze(idModal: any, idStudente: number) {
    this.myPresenza = null;
    this.loading = true;
    this.myForm.patchValue({'id_calendario': this.myItem.id});
    this.myForm.patchValue({'id_studente': idStudente});

    this.service.getStatoPresenza(idStudente, this.myItem.id)
    .subscribe(data => {
      this.myPresenza = data;
      this.myForm.patchValue(data);
      this.onClickPresente();
      this.myForm.patchValue(data);
      this.loading = false;
    },
    (error) => {
      this.formComponentsService.activateLoader(false);
      this.mainEngineService.errorHandler(error);
    });
    this.onClickPresente(true);

    this.modalService.open(idModal, { size: 'lg', windowClass: 'modal-holder', centered: true, backdrop: 'static', keyboard: false,  });
  }

  onClickPresente(reset: boolean = false) {
    if (reset) {
      this.presente = 0;
      this.myForm.patchValue({'presente': ''});
    }
    else {
      this.presente = parseInt(this.myForm.getRawValue().presente);
    }

    switch (this.presente) {
      case 0: // assente
        // rimuovo oraInizio
        this.myForm.patchValue({'inizio': ''});
        this.myForm.get('inizio').clearValidators();
        // rimuovo oraFine
        this.myForm.patchValue({'fine': ''});
        this.myForm.get('fine').clearValidators();
        break;

      case 1: // presente
        let time = new Date();
        let now = time.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric'});

        this.myForm.get('inizio').setValidators([Validators.required]);
        this.myForm.get('fine').setValidators([Validators.required]);
        let defVal = ((now > this.myItem.orarioInizio) && (now < this.myItem.orarioFine)) ? now : this.myItem.orarioInizio;
        this.myForm.patchValue({'inizio': defVal});
        defVal = ((now < this.myItem.orarioFine) && (now > this.myItem.orarioInizio)) ? now : this.myItem.orarioFine;
        this.myForm.patchValue({'fine': defVal});
//        this.myForm.patchValue({'note': 'aaaaaaaaaaa'});


        break;
    }

    this.myForm.get('inizio').updateValueAndValidity();
    this.myForm.get('fine').updateValueAndValidity();

  }


  confermaRegistrazione(modal) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendPresenza(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        modal.close();
        this.getElencoStudenti();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );

  }

  modalClose(modal: any) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        modal.close();
      }
    };

    this.buttonService.confermaAnnulla(obj, '');
  }


  terminaRegistrazione() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.service.terminaRegistrazione(that.idLezione)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.router.navigate(['/calendarioLezioni']);
          },
          (error) => {
            that.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenericoSmart(obj, '');

  }
}
