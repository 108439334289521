import { Component, OnInit } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ComunicazioniService } from '../comunicazioni.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-comunicazioni-elenco',
  templateUrl: './comunicazioni-elenco.component.html',
  styleUrls: ['./comunicazioni-elenco.component.scss']
})
export class ComunicazioniElencoComponent implements OnInit {
  arrayData: any[any];
  titoloPagina: string;
  idCategoria: number = 0;
  idMacrocategoria: number = 0;
  titoloCategoria: string;
  titoloMacrocategoria: string;

  constructor(
    private formComponentsService: FormInputService,
    private service: ComunicazioniService,
    private route: ActivatedRoute,
    private router: Router,
    private mainEngineService: MainEngineService,

  ) {
    this.titoloPagina = this.service.titoloPagina;
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);
    this.route.params.subscribe(
      (params) => {
        this.idCategoria = params.idCategoria;

        this.service.getElencoComunicazioni(this.idCategoria)
        .subscribe(data => {
          this.arrayData = data.items;
          this.idMacrocategoria = data.idMacrocategoria;
          this.titoloCategoria = data.titoloCategoria;
          this.titoloMacrocategoria = data.titoloMacrocategoria;
          this.formComponentsService.activateLoader(false);
        },
        (error) => {
          this.formComponentsService.activateLoader(false);
          this.mainEngineService.errorHandler(error);
        });

      }
    );
  }

  onBack() {
    this.router.navigate(['/comunicazioni/lev1/' + this.idMacrocategoria]);
  }

}
