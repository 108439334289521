import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Home',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'Calendario lezioni',
      icon: 'fas fa-calendar',
      link: '/calendarioLezioni',
    },
    {
      label: 'Classi',
      icon: 'fas fa-users',
      link: '/classi',
    },
    {
      label: 'Comunicazioni',
      icon: 'fas fa-copy',
      link: '/comunicazioni',
    },
    {
      label: 'Grove Music Online',
      icon: 'fas fa-unlock-alt',
      link: '/oxfordGroveMusicOnline',
    },
    {
      label: 'Info versione',
      icon: 'fas fa-info-circle',
      link: '/infoVersione',
    },
];
