import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComunicazioniLev1Component } from './comunicazioni-lev1/comunicazioni-lev1.component';
import { ComunicazioniLev2Component } from './comunicazioni-lev2/comunicazioni-lev2.component';
import { ComunicazioniElencoComponent } from './comunicazioni-elenco/comunicazioni-elenco.component';
import { ComunicazioniDettaglioComponent } from './comunicazioni-dettaglio/comunicazioni-dettaglio.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    ComunicazioniLev1Component,
    ComunicazioniLev2Component,
    ComunicazioniElencoComponent,
    ComunicazioniDettaglioComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule
  ]
})
export class ComunicazioniModule { }
