import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { ComponentsModule } from '../components.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    LoginComponent,
    RecoverComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
  ],
  exports: [
    LoginComponent,
    RecoverComponent,
  ]
})
export class ReservedAccessModule { }
