import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassiService } from '../classi.service';

@Component({
  selector: 'app-classi-elenco',
  templateUrl: './classi-elenco.component.html',
  styleUrls: ['./classi-elenco.component.scss']
})
export class ClassiElencoComponent implements OnInit {
  lastId: number = 0;
  backUrl: string = "/classi";
  options: any[any];
  columns: any[any];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: ClassiService
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }
     });

     this.columns = [
      { columnDef: 'settore', header: 'Settore',    cell: (element: any) => `${element.ADD_settore}`, hideOnSmall: true },
      { columnDef: 'campoDisciplinare', header: 'Campo disc.',    cell: (element: any) => `${element.ADD_campoDisciplinare}` },
      { columnDef: 'numAlunni', header: 'Num. alunni',    cell: (element: any) => `${element.ADD_numAlunni}` },
      { columnDef: 'orePreviste', header: 'Ore prev.',   cell: (element: any) => `${element.ADD_orePreviste}`, hideOnSmall: true},
      { columnDef: 'oreAssegnate', header: 'Ore ass.',   cell: (element: any) => `${element.ADD_oreAssegnate}`, hideOnSmall: true},
      { columnDef: 'stato', header: 'Stato',   cell: (element: any) => `${element.ADD_stato}`},
    ];
  }

  ngOnInit(): void {
    this.options = {
      linkBase: this.backUrl,
      nascondiPulsanteNuovo: true,
    }
  }

}
